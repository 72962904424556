import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { MdToday, MdAccessTime } from "react-icons/md"
import { GiReceiveMoney } from "react-icons/gi"

const Reasons = () => (
  <Row className="reasons my-5 pt-5">
    <Col lg={4} md={4} sm={12} className="reason-col pb-5 mb-md-0 ">
      <span className="reason-icon-block">
        <MdAccessTime />
      </span>
      <span className="reason-description">
        <div className="reason-header h4 mb-2">Підтримка 24/7</div>
        <p>
          Досвідчена команда фахівців готова допомогти вам цілодобово, працює у
          вихідні та святкові дні.
        </p>
      </span>
    </Col>

    <Col lg={4} md={4} sm={12} className="reason-col pb-5 mb-md-0 ">
      <span className="reason-icon-block">
        <MdToday />
      </span>
      <span className="reason-description">
        <div className="reason-header h4 mb-2">Кредитний період</div>
        <p>
          Якщо не встигли оплатити, автоматично активується кредитний період на
          20 днів.
        </p>
      </span>
    </Col>

    <Col lg={4} md={4} sm={12} className="reason-col pb-5 mb-md-0 ">
      <span className="reason-icon-block">
        <GiReceiveMoney />
      </span>
      <span className="reason-description">
        <div className="reason-header h4 mb-2">Акції</div>
        <p>
          Безкоштовні місяці при оплаті за півроку і більше, бонуси за
          підключених друзів.
        </p>
      </span>
    </Col>
  </Row>
)

export default Reasons
